<template>
  <div class="page bg-white" id="reorganize">
    <div>
      <el-tabs size="small" type="card" v-model="selectTab" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                     :name="item.value"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="contens">
      <!--基本信息-->
      <div v-show="selectTab == 0">
        <el-row>
          <el-form :model="logoutInfo" :rules="logoutRules" ref="logoutInfo" label-width="200px"
                   size="small" class="demo-ruleForm">
            <div class="minTitle">分类与编号</div>
            <el-col :span="22">
              <el-form-item label="藏品名称" prop="collectionName"
                            :rules="[{ required: true, message: '请输入藏品名称', trigger: 'blur' }]">
                <el-input :disabled="inputDisable" :rows="1" type="textarea"
                          v-model.trim="logoutInfo.collectionName"
                          placeholder="请输入藏品名称(限300字)"
                          maxlength="300" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="藏品类型" prop="collectionType"
                            :rules="[{ required: true, message: '请选择藏品类型', trigger: 'change' }]">
                <el-select :disabled="true" v-model="logoutInfo.collectionType"
                           placeholder="请选择藏品类型"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in typeList"
                      :key="item.id"
                      :label="item.collectionTypeName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="是否鉴定" prop="identified">
                <el-select :disabled="inputDisable" v-model="logoutInfo.identified"
                           placeholder="请选择" style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in identifyList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="藏品分类" prop="archivesBasicDataId"
                            :rules="[{ required: true, message: '请选择藏品分类', trigger: 'change' }]">
                <el-select :disabled="inputDisable" v-model="logoutInfo.archivesBasicDataId"
                           placeholder="请选择藏品分类"
                           style="width: 100%"
                           @change="(value)=>{selectCascader(value,'',1)}"
                           clearable>
                  <el-option
                      v-for="item in classList"
                      :key="item.id"
                      :label="item.archivesBasicName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="二级分类" prop="twoType">
                <el-select :disabled="inputDisable" v-model="logoutInfo.twoType"
                           placeholder="请选择二级分类"
                           style="width: 100%"
                           @change="(value)=>{selectCascader(value,'',2)}"
                           clearable>
                  <el-option
                      v-for="item in classListSon"
                      :key="item.id"
                      :label="item.archivesBasicName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="级别" prop="collectionLevel"
                            :rules="[{ required: true, message: '请选择藏品级别', trigger: 'change' }]">
                <el-select :disabled="inputDisable" v-model="logoutInfo.collectionLevel"
                           placeholder="请选择藏品级别"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in leaveList"
                      :key="item.level"
                      :label="item.levelName"
                      :value="item.level">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="辅助账编号" prop="helpNum">
                <el-input :disabled="inputDisable" v-model="logoutInfo.helpNum"
                          placeholder="请输入藏品编号(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="完残程度" prop="integrity"
                            :rules="[{ required: true, message: '请选择完残程度', trigger: 'change' }]">
                <el-select :disabled="inputDisable" v-model="logoutInfo.integrity"
                           placeholder="请选择完残程度"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in integrityList"
                      :key="item.id"
                      :label="item.levelName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="库存状态" prop="cStoreState"
                            :rules="[{ required: true, message: '请选择库存状态', trigger: 'change' }]">
                <el-select :disabled="inputDisable" v-model="logoutInfo.cStoreState"
                           placeholder="请选择库存状态"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in cStoreStateList"
                      :key="item.id"
                      :label="item.levelName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="完残情况" prop="integrityInfo">
                <el-input :disabled="inputDisable" v-model="logoutInfo.integrityInfo"
                          placeholder="请输入完残情况(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-form>


          <el-form :model="collectionDataCopy" ref="collectionDataCopy" label-width="200px"
                   size="small" class="demo-ruleForm">
            <el-col :span="24">
              <div class="minTitle">复仿制品信息</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="仿品号" prop="copyNum">
                <el-input :disabled="inputDisable" v-model="collectionDataCopy.copyNum"
                          placeholder="请输入仿品号(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="经办人" prop="handledBy">
                <el-input :disabled="inputDisable" v-model="collectionDataCopy.handledBy"
                          placeholder="请输入经办人(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="复刻藏品名称" prop="copyName">
                <el-input :disabled="inputDisable" v-model="collectionDataCopy.copyName"
                          placeholder="请输入复刻藏品名称(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="原名" prop="oldName">
                <el-input :disabled="inputDisable" v-model="collectionDataCopy.oldName"
                          placeholder="请输入原名(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="复仿制类型" prop="copyType">
                <el-select
                    v-model="collectionDataCopy.copyType"
                    clearable
                    :disabled="inputDisable"
                    placeholder="请选择复仿制类型"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('copy_type')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="复仿制方式" prop="copyWay">
                <el-input :disabled="inputDisable" v-model="collectionDataCopy.copyWay"
                          placeholder="请输入复仿制方式(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="复仿制日期" prop="copyDate">
                <el-date-picker
                    v-model="collectionDataCopy.copyDate"
                    :disabled="inputDisable"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    clearable
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="复仿制品描述" prop="copyDesc">
                <el-input :disabled="inputDisable" :rows="3" type="textarea"
                          v-model.trim="collectionDataCopy.copyDesc"
                          placeholder="请输入复仿制品描述(限500字)"
                          maxlength="500" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-form>


          <!--元数据-->
          <el-form size="small" :model="inputForm" :rules="rules" ref="inputForm"
                   label-width="200px" :disabled="inputDisable">
            <el-col :span="24">
              <div class="minTitle">藏品基本信息</div>
              <div v-loading="loading"
                   v-if="logoutInfo.archivesBasicDataId && logoutInfo.archivesBasicDataId.length != 0">
                <el-col
                    :span="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length && item.maxLength && item.maxLength > 100 ? 22 : 11"
                    v-for="(item, index) in config" :key="index">
                  <el-form-item :label="item.forName ? item.forName : item.basicName + '：'"
                                :prop="item.code">
                    <!--字符-->
                    <el-input :type="item.maxLength && item.maxLength > 100 ? 'textarea': ''"
                              v-model.trim="inputForm[item.code]"
                              :maxlength="item.maxLength || 300"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                    <!--数值、浮点数-->
                    <el-input v-model.trim="inputForm[item.code]" maxlength="11"
                              @input="getNumType(item)"
                              @blur="verifyNum(item)"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                    <!--下拉选择-->
                    <el-select class="w100i" v-model="inputForm[item.code]"
                               :multiple="item.archivesBasicMultiselectList[0].selectBy == 1"
                               collapse-tags
                               :placeholder="item.forName ? '请选择' + item.forName : '请选择' + item.basicName"
                               clearable
                               v-if="item.archivesBasicMultiselectList.length">
                      <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                 :label="v.archivesBasicValue"
                                 :value="v.archivesBasicValue"></el-option>
                    </el-select>
                    <!--时间-->
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd'">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyyMMdd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd'">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </div>
              <div v-else>
                <div class="baseTip">请先选择藏品分类</div>
              </div>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </div>
    <div>
      <div v-if="butType != 0 && butType != 2 && butType != 4"
           class="text_center">
        <el-button size="small" v-if="!see" type="primary" @click="switchData(1)">上一条</el-button>
        <el-button size="small" v-if="!see" type="primary" @click="switchData(0)">下一条</el-button>

        <el-button size="small" v-if="butType == 3 && see != 1 && logoutInfo.processState == 1" type="primary"
                   @click="thread(3)">通过
        </el-button>
        <el-button size="small" v-if="butType == 3 && see != 1 && logoutInfo.processState == 1" type="danger"
                   @click="thread(2)">驳回
        </el-button>
        <el-button size="small" @click="returnPage(1)">关闭</el-button>
      </div>
      <div v-else-if="!inputDisable" class="text_center">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="pagePreview()">预览</el-button>
      </div>
      <div v-else class="text_center">
        <el-button size="small" type="primary" @click="backDate()">上一步</el-button>
        <el-button size="small" v-if="butType == 2 || butType == 4" type="primary" @click="saveAsDraft(2)">保存
        </el-button>
        <el-button size="small" v-if="butType == 0" type="primary" @click="saveAsDraft(2)">保存为草稿
        </el-button>
        <el-button size="small" v-if="butType == 0 || butType == 4" type="primary" @click="saveAsDraft(1)">提交
        </el-button>
      </div>
    </div>
    <reviewSuggestions ref="suggestions" @updataStatus="returnPage(1)"></reviewSuggestions>
    <see-three-file ref="seethree"></see-three-file>
  </div>
</template>

<script>
import resumeUpload from "@/components/Upload/resumeUpload.vue";
import pdf from "vue-pdf";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";
import treeSelect from "@/components/treeSelect/treeSelect.vue";
import SelectTree from "@/components/treeSelect/treeSelect.vue";
import {Base64} from 'js-base64'
import SeeThreeFile from "@/views/modules/collection/accounts/module/seeThreeFile.vue";

export default {
  name: "addProducts",
  components: {SeeThreeFile, SelectTree, resumeUpload, pdf, reviewSuggestions, treeSelect},
  data() {
    return {
      selectTab: 0,
      tabsList: [
        {
          name: '基本信息',
          value: '0',
        },
      ],

      id: '',
      logoutInfo: {
        collectionType: '',
        archivesBasicDataId: '',
        identified: '',
        collectionLevel: '',
        helpNum: '',
        collectionName: '',
        integrity: '',
        cStoreState: '',
        integrityInfo: '',
      },
      logoutRules: {},

      collectionDataCopy: {
        copyNum: '',
        handledBy: '',
        copyName: '',
        oldName: '',
        copyType: '',
        copyWay: '',
        copyDate: '',
        copyDesc: '',
      },

      integrityList: [
        {
          id: '残缺',
          levelName: '残缺',
        },
        {
          id: '基本完整',
          levelName: '基本完整',
        },
        {
          id: '完整',
          levelName: '完整',
        },
        {
          id: '严重残缺',
          levelName: '严重残缺',
        },
      ],
      cStoreStateList: [
        {
          id: 0,
          levelName: '待入库',
        },
        {
          id: 1,
          levelName: '入库中',
        },
        {
          id: 2,
          levelName: '在库',
        },
        {
          id: 3,
          levelName: '待出库',
        },
        {
          id: 4,
          levelName: '出库中',
        },
        {
          id: 5,
          levelName: '已出库',
        },
      ],

      typeList: [],
      classList: [],
      classListSon: [],
      leaveList: [],
      identifyList: [
        {
          label: '是',
          value: 0,
        },
        {
          label: '否',
          value: 1,
        },
      ],

      //元数据字段
      config: [],
      inputForm: {},
      rules: {},
      loading: false,

      pdfUrl: '',
      typePage: '', //页面的标识
      inputDisable: false,//是否禁用所有藏品输入框
      butType: 0, //页面按钮状态 0新增 1详情页 2修改 3审核 4重新提交
      programExit: false,//是否用代码退出


      culturalRelicsTypeList: [],
      latitudeList: [
        {
          value: '0',
          label: '北纬',
        },
        {
          value: '1',
          label: '南纬',
        },
      ],
      longitudeList: [
        {
          value: '0',
          label: '东经',
        },
        {
          value: '1',
          label: '西经',
        },
      ],

      see: false,

      registrationSearch: {}, //上一条下一条存储

      returnPageStatus: null,//判断是否是点返回或退出按钮,
    }
  },

  mounted() {
    //页面按钮控制
    this.butType = this.$route.query.butType
    this.id = this.$route.query.id
    this.typePage = this.$route.query.typePage
    this.see = this.$route.query.see

    this.setPageTitle()
    if (this.butType == 1 || this.butType == 3) {
      this.inputDisable = true
    }
    if (this.$route.query.id) {
      //获取藏品分类
      this.getTreeData()
    } else {
      this.getTreeData()
    }
    //获取选择下拉框
    this.getSelectData()
  },

  methods: {
    //获取详情
    getDataDetail() {
      let urlwq = this.api.collection.collectiondataGetById
      this.$axios(urlwq + this.id, {}, 'get').then((res) => {
        if (res.status) {
          this.logoutInfo = res.data
          this.logoutInfo.archivesBasicDataId = this.logoutInfo.oneType
          this.logoutInfo.collectionLevel = this.logoutInfo.collectionLevel * 1
          this.logoutInfo.identified = this.logoutInfo.identified * 1
          this.logoutInfo.collectionType = this.logoutInfo.collectionType * 1
          this.selectCascader('', 1)
          if (res.data.collectionDataCopy) {
            this.collectionDataCopy = res.data.collectionDataCopy
          }
        }
      })
    },

    // 获取数据分类
    getTreeData(archivesBasicDataId) {
      this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
        if (res.status) {
          this.classList = res.data
          this.$nextTick(() => {
            console.log(archivesBasicDataId)
            if (archivesBasicDataId) {
              this.logoutInfo.archivesBasicDataId = archivesBasicDataId
            }
            if (this.id) {
              this.getDataDetail()
            }
          })
        }
      })
    },

    getSelectData() {
      this.$axios(this.api.collection.listSelect).then(data => {
        if (data.status) {
          this.typeList = data.data
          this.typeList.forEach(item => {
            if (item.collectionTypeName == '复制品/仿制品') {
              this.logoutInfo.collectionType = item.id
            }
          })
        }
      })

      this.$axios(this.api.collection.listLevelSelect).then(data => {
        if (data.status) {
          this.leaveList = data.data
          // this.leaveList.forEach(item => {
          //     if (item.levelName == "未定级") {
          //         this.logoutInfo.collectionLevel = item.level
          //     }
          // })
        }
      })
    },

    //藏品分类级联选择 type 1详情需要给数据 2征集
    selectCascader(value, type,num) {
      if (this.logoutInfo.archivesBasicDataId) {
        if (num == 1){
          this.logoutInfo.twoType = ''
        }
        this.classList.forEach(item => {
          if (item.id == this.logoutInfo.archivesBasicDataId) {
            this.classListSon = item.children
          }
        })
        if (this.logoutInfo.twoType) {
          this.getMetadata(this.logoutInfo.twoType, type)
        } else {
          this.getMetadata(this.logoutInfo.archivesBasicDataId, type)
        }
      } else {
        this.logoutInfo.twoType = ''
        this.classListSon = []
        this.config = []
        this.inputForm = {}
        this.rules = {}
      }
    },

    getMetadata(archivesBasicDataId, type){
      this.loading = true
      this.$axios(this.api.collection.basicdataGetById + '/' + archivesBasicDataId).then(data => {
        if (data.status) {
          this.config = data.data.archivesBasicDataGroup
          this.config.map(item => {
            this.$set(this.inputForm, item.code, null)
            this.$nextTick(() => {
              this.$refs['inputForm'].clearValidate()
            })
            let basicName = item.forName ? item.forName : item.basicName
            if (item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length) {
              var checkText = (rule, value, callback) => {
                if (item.minLength && value && value.length < item.minLength * 1) {
                  callback(new Error(basicName + '字数必须大于或等于' + item.minLength));
                } else {
                  callback()
                }
              };
              this.$set(this.rules, item.code, [
                {
                  required: item.dataRequired == '0' ? true : false,
                  message: '请输入' + basicName,
                  trigger: 'blur'
                },
                {validator: checkText, trigger: 'blur'}
              ])
            }
            if (item.archivesBasicMultiselectList.length) {
              this.$set(this.rules, item.code, [
                {
                  required: item.dataRequired == '0' ? true : false,
                  message: '请选择' + basicName,
                  trigger: 'blur'
                },
              ])
            }
            if (item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double') {
              var checkInteger = (rule, value, callback) => {
                if (item.archivesBasicTypeCode == 'double' && value && !this.validate.isPrice(value)) {
                  callback(new Error('请输入正确的' + basicName));
                } else if (item.minLength && value && value * 1 < item.minLength * 1) {
                  callback(new Error(basicName + '必须大于或等于' + item.minLength));
                } else if (item.maxLength && value && value * 1 > item.maxLength * 1) {
                  callback(new Error(basicName + '必须小于或等于' + item.maxLength));
                } else {
                  callback();
                }
              };
              this.$set(this.rules, item.code, [
                {
                  required: item.dataRequired == '0' ? true : false,
                  message: '请输入' + basicName,
                  trigger: 'blur'
                },
                {validator: checkInteger, trigger: 'blur'}
              ])
            }
            if (item.archivesBasicTypeCode == 'date') {
              var checkDate = (rule, value, callback) => {
                const regDate = /^(\d{4})(\d{2})(\d{2})$/;
                if (item.minLength && value && new Date(value.replace(regDate, "$1-$2-$3")) < new Date(item.minLength.replace(regDate, "$1-$2-$3"))) {
                  callback(new Error(basicName + '必须大于或等于' + item.minLength));
                } else if (item.maxLength && value && new Date(value.replace(regDate, "$1-$2-$3")) > new Date(item.maxLength.replace(regDate, "$1-$2-$3"))) {
                  callback(new Error(basicName + '必须小于或等于' + item.maxLength));
                } else {
                  callback();
                }
              };
              this.$set(this.rules, item.code, [
                {
                  required: item.dataRequired == '0' ? true : false,
                  message: '请选择' + basicName,
                  trigger: 'blur'
                },
                {validator: checkDate, trigger: 'blur'}
              ])
            }
            this.loading = false
          })
          if (type == 1) {
            Object.keys(this.inputForm).forEach(key => {
              this.inputForm[key] = this.logoutInfo.archivesDataJSONObject[key]
            })
          }
        }
      })
    },


    //选中文物类别
    selectCulturalRelics(data) {
      if (data == 6) { //其它
        this.logoutInfo.culturalRelicsType = ''
      } else {
        this.logoutInfo.other = ''
        this.$axios(this.api.collection.collectionImmovableCulturalRelicType + data, {}, 'get').then(data => {
          if (data.status) {
            this.culturalRelicsTypeList = data.data
          }
        })
      }
    },

    //预览三维
    threeFile(threedate) {
      this.$refs.seethree.init(threedate)
    },

    //切换信息分类
    handleClick(tab, event) {
      if (this.selectTab != 2) {
        let audioList = document.getElementsByClassName('audioDom')
        audioList.forEach(item => {
          item.pause()
        })
      }
      if (this.selectTab != 3) {
        let videoList = document.getElementsByClassName('videoDom')
        videoList.forEach(item => {
          item.pause()
        })
      }
    },

    //预览
    pagePreview() {
      this.$refs['logoutInfo'].validate((valid) => {
        this.$refs['inputForm'].validate((valid2) => {
          if (valid && valid2) {
            this.inputDisable = true
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      });

    },

    //上一步
    backDate() {
      this.inputDisable = false
    },

    // type 2保存  1提交
    saveAsDraft(type) {
      this.logoutInfo.archivesData = this.inputForm
      let datalad = {
        id: this.id,
        ...this.logoutInfo,
        oneType:this.logoutInfo.archivesBasicDataId,
        entryState: 0,
        collectionDataCopy: this.collectionDataCopy,
        files: [],
      }
      if (type == 2) {
        this.$axios(this.api.collection.draftSave, datalad, 'post').then((res) => {
          if (res.status) {
            this.$message.success('保存成功！')
            this.programExit = true
            this.returnPage(1)
          } else {
            this.$message.error(res.msg);
          }
        })
      } else {
        this.$confirm('您是否确认提交数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let fetchUrl = this.api.collection.submit
          // if (this.typePage == 'ledger' || this.typePage == 'auxiliaryAccount') {
          //     fetchUrl = this.api.collection.updateCollectionDataUnderReview
          // }
          this.$axios(fetchUrl, datalad, 'post').then((res) => {
            if (res.status) {
              this.$message.success('提交成功！')
              this.programExit = true
              this.returnPage(1)
            } else {
              this.$message.error(res.msg);
            }
          })
        })
      }
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      let fetchUrl = this.api.collection.collectiondatacopyListNextOrPre
      if (this.typePage == 'productsExamine') {
        fetchUrl = this.api.collection.collectiondatacopyListRevdNextOrPre
      }
      this.$axios(fetchUrl, {
        nextInfo: num,
        ...this.registrationSearch
      }, 'post').then((res) => {
        if (res.status) {
          if (res.data) {
            this.id = res.data.id
            this.getDataDetail()
            if (num == 0) {
              this.registrationSearch.viewCurrent++
            } else {
              this.registrationSearch.viewCurrent--
            }
          } else {
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$router.back()
    },

    //num 3为通过 2为驳回
    thread(num) {
      this.$refs.suggestions.init(2, [this.logoutInfo], num, 0)
    },

    //限制输入大小 type 1保留两位小数
    numberSize(moderNum, max, min, type) {
      if (this.logoutInfo[moderNum] > max) {
        this.logoutInfo[moderNum] = max
      }
      if (this.logoutInfo[moderNum] < min) {
        this.logoutInfo[moderNum] = min
      }
    },

    //保留两位小数
    sav2Decimal(moderNum) {
      if (this.logoutInfo[moderNum] || this.logoutInfo[moderNum] == 0) {
        this.logoutInfo[moderNum] = this.logoutInfo[moderNum].toFixed(2)
      }
    },

    downFiile(url, name) {
      const xhr = new XMLHttpRequest();
      // 使用open()方法初始化一个请求，第一个参数为请求的类型，第二个参数为请求的地址，第三个参数为是否异步
      xhr.open('GET', url, true);
      // 设置响应的数据类型
      xhr.responseType = 'blob';
      // 当请求加载完成时，触发onload事件
      xhr.onload = () => {
        // 如果请求的状态码为200，表示请求成功
        if (xhr.status === 200) {
          // 创建一个blob对象，第一个参数为响应的数据，第二个参数为blob对象的类型
          const blob = new Blob([xhr.response], {type: xhr.getResponseHeader('content-type')});
          // 创建一个a标签
          const link = document.createElement('a');
          // 为a标签设置href属性，值为blob对象的URL
          link.href = URL.createObjectURL(blob);
          // 为a标签设置下载文件名
          link.download = name;
          // 点击a标签，开始下载文件
          link.click();
        }
      };
      // 发送请求
      xhr.send();
    },

    // 获取元数据类型设置输入正则
    getNumType(item) {
      let regular = /[^\d]/g
      if (item.archivesBasicTypeCode == "double") {
        regular = /[^\d.]/g
      }
      this.$set(this.inputForm, item.code, this.inputForm[item.code].replace(regular, ''))
    },

    // 浮点数保留两位小数
    verifyNum(item) {
      if (item.archivesBasicTypeCode == "double") {
        let val = this.inputForm[item.code]
        let pointNum = val.toString().split(".").length - 1
        if (val && !this.validate.isPrice(val) && pointNum <= 1) {
          let num = (val * 1).toFixed(2)
          this.$set(this.inputForm, item.code, num)
        }
      }
    },

    setPageTitle() {
      let name = '复仿制品详情'
      if (this.butType == 0) {
        name = '新增复仿制品'
      } else if (this.butType == 2) {
        name = '修改复仿制品'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },
  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
      next();
    } else if (this.butType == 0 || this.butType == 2 || this.butType == 4) {
      this.$confirm('当前数据未保存，您确定退出吗？', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //进去别的页面
        if (this.returnPageStatus){
          this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
        }
        next();
      }).catch(() => {
        next(false);
      });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.contens {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
}

.fileIcon {
  cursor: pointer;
  font-size: 30px;
}

.baseTip {
  text-align: center;
  height: calc(100vh - 450px);
  line-height: calc(100vh - 450px);
}

.zheaug {
  position: relative;
}

.zheuag {
  position: absolute;
  background: rgba(192, 200, 217, 0.3);
  z-index: 99;
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
}

.zheuag2 {
  background: transparent;

  .adfad1 {
    width: 32%;
    height: 100%;
    background: rgba(192, 200, 217, 0.3);
  }
}
</style>